.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 4rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  position: sticky;
  top: 0;
  background-color: #ffff;
  z-index: 999;
  
}
.logo {
  font-size: 20px;
  font-weight: 600;
}

.navbar {
  font-weight: 600;
  display: flex;
}

#nav-links {
  list-style: none;
}

#nav-links .nav-link {
  display: inline-block;
  margin-right: 1rem;
}
#nav-links .nav-link a {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
  transition: all .23s;
}
#nav-links .nav-link a:hover {
  color: #147efb;
}
.nav-icon {
  font-size: 20px;
  display: none;
}



@media screen and (max-width: 960px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 4rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    position: sticky;
    top: 0;
    background-color: #ffff;
    z-index: 1;
    padding: 1.5rem 2rem;
  }
  .nav-icon {
    display: block;
    z-index: 1;
  }
  #nav-links {
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #ffff;
    transition: transform 0.5s linear;
    z-index: 2;
  }
  .active {
    transform: translateX(100%);
  }
  .nav-link {
    margin: 15px 0;
    font-size: 20px;
  }
}
